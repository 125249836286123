import { useEffect } from "react";
import { useAccount, useConnect } from "wagmi";

export const useConnectLedgerProvider = () => {
  const { connectors, connect } = useConnect();
  const { isConnected } = useAccount();

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      window.ethereum?.isLedgerLive &&
      !isConnected
    ) {
      const injectedLedgerConnector = connectors.find(
        (connector) => connector.id === "injected"
      );

      connect({ connector: injectedLedgerConnector });
    }
  }, [connect, connectors, isConnected]);
};
