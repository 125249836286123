"use client";

import React, { useState, useEffect, useCallback } from "react";
import { Protocol } from "@figmentjs/protocols";
import Wallet, { formatEther, fromHex, parseEther } from "../../../../index";
import {
  getLsEthBalance,
  getIsAllowed,
  getLsEthConversionRate,
} from "../utils";

type UseLiquidStaking = {
  isAllowed: boolean;
  lsEthBalance: string;
  lsEthConversionRate: bigint;
  lsEthBalanceInEth: string;
  isInitialized: boolean;
};

const defaultValues: UseLiquidStaking = {
  isAllowed: false,
  lsEthBalance: "0",
  lsEthConversionRate: BigInt(0),
  lsEthBalanceInEth: "0",
  isInitialized: false,
};

const UseLiquidStakingContext =
  React.createContext<UseLiquidStaking>(defaultValues);

export const LiquidStakingProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { publicClient, account, network } =
    Wallet.useWallet<Protocol.ETHEREUM>();
  const { transactionHash, isSendingTransaction } = Wallet.useWallet();

  const [lsEthBalanceHex, setLsEthBalanceHex] = useState<
    `0x${string}` | null
  >();
  const [isAllowed, setIsAllowed] = useState<boolean>(true);
  const [lsEthConversionRateHex, setLsEthConversionRateHex] = useState<
    `0x${string}` | null
  >();

  const init = useCallback(async () => {
    if (!publicClient || !network || !account) return;

    const [_lsEthBalance, _isAllowed, _lsEthConversionRate] = await Promise.all(
      [
        getLsEthBalance(publicClient, account as `0x${string}`, network),
        getIsAllowed(publicClient, account as `0x${string}`, network),
        getLsEthConversionRate(publicClient, network),
      ]
    );

    setLsEthBalanceHex(_lsEthBalance);

    setIsAllowed(
      // "0x" is essentially an empty string/null.
      // We'll set the allowed status to false in this scenario.
      _isAllowed && _isAllowed !== "0x"
        ? BigInt(_isAllowed) === BigInt(1)
        : false
    );

    setLsEthConversionRateHex(
      // "0x" is essentially an empty string/null.
      // We'll set the conversion to undefined in this scenario.
      _lsEthConversionRate !== "0x" ? _lsEthConversionRate : undefined
    );
  }, [account, network, publicClient]);

  useEffect(() => {
    init();
  }, [account, init, network, publicClient]);

  useEffect(() => {
    const updateLsEthBalance = async () => {
      if (!publicClient || !network || !account) return;

      if (transactionHash && !isSendingTransaction) {
        const _lsEthBalance = await getLsEthBalance(
          publicClient,
          account as `0x${string}`,
          network
        );
        setLsEthBalanceHex(_lsEthBalance);
      }
    };

    updateLsEthBalance();
  }, [transactionHash, isSendingTransaction, publicClient, account, network]);

  const lsEthBalanceInWei = fromHex(lsEthBalanceHex || "0x0", "bigint");
  const lsEthConversionRate = fromHex(
    lsEthConversionRateHex || "0x1",
    "bigint"
  );
  const lsEthBalance = formatEther(lsEthBalanceInWei);
  const lsEthInWei =
    (lsEthBalanceInWei * parseEther("1", "wei")) / lsEthConversionRate;
  const lsEthBalanceInEth = formatEther(lsEthInWei);

  return (
    <UseLiquidStakingContext.Provider
      value={{
        isAllowed,
        lsEthBalance,
        lsEthConversionRate,
        lsEthBalanceInEth,
        isInitialized: !!lsEthBalanceHex,
      }}
    >
      {children}
    </UseLiquidStakingContext.Provider>
  );
};

export const useLiquidStaking = () => {
  const context = React.useContext(UseLiquidStakingContext);

  return context;
};
