"use client";

import { useContext, useEffect, useState } from "react";
import {
  useAccount,
  useBalance,
  useNetwork,
  useFeeData,
  useWaitForTransaction,
  useSendTransaction,
  usePublicClient,
  useDisconnect,
} from "wagmi";
import { toHex } from "viem";
import { useWeb3Modal, useWeb3ModalState } from "@web3modal/wagmi/react";
import { useRollbar } from "@figmentjs/rollbar-client";
import {
  chainIdToNetworkMap,
  chainIdToNamespaceMap,
} from "../../web3modal/ethereum/ethereum.web3modal.config";
import { UseWalletProviderContext } from "../use-wallet-provider";
import {
  SendTransaction,
  UseGenericWalletReturnType as UseWalletReturnType,
} from "../use-wallet.types";
import {
  getSendTransactionError,
  getSendTransactionParams,
  useConfirmTransaction,
  fetchAndSetTransactionHash,
  SAFE_CONNECTOR_NAME,
} from "../use-wallet.utils";
import { Network, Protocol } from "@figmentjs/protocols";
import { useCurrentWalletAddress } from "../../use-current-eth-address";

export { isAddress, getAddress, fromHex, parseEther, formatEther } from "viem";
export type { PublicClient, Address } from "wagmi";
export { useSignMessage } from "wagmi";

export const useEthereumWallet = (): UseWalletReturnType<Protocol.ETHEREUM> => {
  const {
    isSendingTransaction,
    sendTransactionError,
    setSendTransactionError,
    setIsSendingTransaction,
    transactionHash,
    setTransactionHash,
    name,
    setName,
    isTestnetMode,
  } = useContext(UseWalletProviderContext);
  const { address, connector, isConnecting, isReconnecting } = useAccount();
  const { open: isModalOpen } = useWeb3ModalState();
  const { open: openWeb3Modal } = useWeb3Modal();
  const { disconnect } = useDisconnect();
  const { data: balanceData } = useBalance({ address });
  const { chain } = useNetwork();
  const { data: feeData } = useFeeData();
  const publicClient = usePublicClient();
  const {
    data: sendTxData,
    error: sendTxError,
    sendTransaction: wagmiSendTransaction,
  } = useSendTransaction();
  const { data: waitTxData, error: waitTxError } = useWaitForTransaction({
    hash: transactionHash as `0x${string}`,
  });
  const rollbar = useRollbar();
  const [tempTxHash, setTempTxHash] = useState<`0x${string}`>();
  const { setCurrentWalletAddress } = useCurrentWalletAddress();

  const isWalletConnect = connector?.id === "walletConnect";

  useEffect(() => {
    if (sendTxError) {
      setSendTransactionError(
        getSendTransactionError({
          error: sendTxError,
          rollbar,
          name,
          isWalletConnect,
        })
      );
      setIsSendingTransaction(false);
    } else if (waitTxError) {
      setSendTransactionError(
        getSendTransactionError({
          error: waitTxError,
          rollbar,
          name,
          isWalletConnect,
          isWaitTxError: true,
        })
      );
      setIsSendingTransaction(false);
    }
  }, [
    sendTxError,
    waitTxError,
    setSendTransactionError,
    rollbar,
    setIsSendingTransaction,
    isWalletConnect,
    name,
  ]);

  useEffect(() => {
    const main = async () => {
      const hash = sendTxData?.hash || tempTxHash;

      if (hash) {
        await fetchAndSetTransactionHash({
          chain,
          hash,
          setTransactionHash,
          name,
          connectorName: connector?.name,
          address,
        });
      }
    };

    main();
  }, [sendTxData?.hash, tempTxHash, setTransactionHash, address, chain, name]);

  useEffect(() => {
    const main = async () => {
      if (connector) {
        const provider = await connector?.getProvider();
        const metadata = provider?.signer?.session?.peer?.metadata;
        setName(metadata?.name);
      }
    };

    main();
  }, [connector, name, setName]);

  useEffect(() => {
    setCurrentWalletAddress(address);
  }, [address]);

  useConfirmTransaction({
    waitTxData,
    setSendTransactionError,
    setIsSendingTransaction,
    chain,
    hash: transactionHash as `0x${string}`,
  });

  const sendTransaction: SendTransaction = async ({
    amount,
    ...remainingArgs
  }) => {
    setSendTransactionError(undefined);
    setIsSendingTransaction(true);

    const sendTxParams = getSendTransactionParams({
      amount,
      account: address!,
      ...remainingArgs,
    });

    if (isWalletConnect) {
      const provider = await connector?.getProvider();
      const topic = provider?.signer?.session?.topic;
      const chainId = chain && chainIdToNamespaceMap[chain.id];

      try {
        const txHash = await provider.signer.client.request({
          topic,
          chainId,
          request: {
            method: "eth_sendTransaction",
            params: [
              {
                ...sendTxParams,
                gas: toHex(sendTxParams.gas!),
                value: toHex(sendTxParams.value),
              },
            ],
          },
          expiry: 3 * 60 * 60, // 3 hours in seconds
        });

        if (!txHash || typeof txHash !== "string") {
          throw new Error("Transaction hash not available");
        }

        setTempTxHash(txHash as `0x${string}`);
      } catch (error) {
        setSendTransactionError(
          getSendTransactionError({ error, rollbar, name, isWalletConnect })
        );
        setIsSendingTransaction(false);
      }

      return;
    }

    wagmiSendTransaction(sendTxParams);
  };

  return {
    account: address,
    isConnecting: (isConnecting && isModalOpen) || isReconnecting,
    balance: balanceData && balanceData.formatted,
    network: chain && chainIdToNetworkMap[chain.id],
    maxFeePerGas: feeData?.maxFeePerGas?.toString(),
    isWalletConnect,
    publicClient,
    sendTransaction,
    isSendingTransaction,
    sendTransactionError,
    setSendTransactionError,
    transactionHash,
    setTransactionHash,
    walletName: isWalletConnect ? name : connector?.name,
    connect: () => openWeb3Modal({ view: "Connect" }),
    disconnect:
      connector &&
      connector.name !== SAFE_CONNECTOR_NAME &&
      !window.ethereum?.isLedgerLive
        ? disconnect
        : undefined,
    // Ethereum wallets can be on different networks than what the app is expecting,
    // so this gives components a way to know which network the app is expecting.
    displayedNetwork: isTestnetMode ? Network.HOLESKY : Network.MAINNET,
  };
};
