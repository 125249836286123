export enum DAppObjectsEnum {
  STAKING_FLOW = "Staking Flow",
  UNSTAKING_FLOW = "Unstaking Flow",
  DAPP_NAVIGATION = "Dapp Navigation",
  REWARDS = "Rewards",
}

export enum DAppActionEnum {
  STEP_RENDERED = "Step Rendered",
  CONTINUE_BTN_CLICKED = "Continue Button Clicked",
  BACK_BTN_CLICKED = "Back Button Clicked",
  STAKE_MORE_BTN_CLICKED = "Stake More Button Clicked",
  SEE_ACTIVITY_BTN_CLICKED = "SEE_ACTIVITY_BTN_CLICKED",
  DONE_BUTTON_CLICKED = "Done Button Clicked",
  TRY_AGAIN_BTN_CLICKED = "Try Again Button Clicked",
  TX_SIGNED_AND_BROADCASTED = "Transaction Signed And Broadcasted",
  TAB_CLICKED = "Tab Clicked",
  FAQ_CLICKED = "FAQ Link Clicked",
  BANNER_CLICKED = "Banner Clicked",
  BANNER_HIDE = "Banner Hide",
}
