import { Protocol } from "@figmentjs/protocols";
import { GetSendTransactionErrorProps } from "../use-wallet.utils";

const TX_TIMEOUT_ERRORS = [
  // Phantom
  "TransactionExpiredBlockheightExceededError",

  // Onekey + WalletConnect
  "WalletSendTransactionError",
];

export const getSendTransactionError = ({
  error,
  rollbar,
  isWalletConnect,
  name,
}: GetSendTransactionErrorProps) => {
  if (
    [
      "User rejected the request.",
      "Transaction cancelled",
      "User rejected.",
    ].includes(error?.message)
  ) {
    return "TX_CANCELED";
  }

  if (TX_TIMEOUT_ERRORS.includes(error?.name)) {
    return "TX_TIMEOUT";
  }

  rollbar.error(
    `sendTransaction() Error: ${error?.details || error?.message || ""}`,
    JSON.stringify(error),
    {
      walletDetails: {
        isWalletConnect,
        name,
        protocol: Protocol.SOLANA,
      },
    }
  );

  return JSON.stringify(error);
};
