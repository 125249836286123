import { useCallback } from "react";
import {
  DAppPageEventProperties,
  DAppTrackEvent,
  DAppTrackEventProperties,
  DAppNavEventProperties,
  client,
  SegmentSettings,
} from "@figmentjs/analytics/client";
import { baseURL } from "@app/utils/constants";

/* Segment Custom Configuration for Proxy and Cookie + LocalStorage Keys
 * Documentation on Proxy: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/custom-proxy/
 * Documentation on Custom Cookies and LocalStorage Keys: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/cookie-validity-update/#user-settings
 */
const SEGMENT_PROXY_URL = "/api/segment/v1";
const settings: SegmentSettings = {
  integrations: {
    "Segment.io": {
      apiHost: `${baseURL}${SEGMENT_PROXY_URL}`.split("//")[1],
      protocol: baseURL.startsWith("https") ? "https" : "http",
    },
  },
  user: {
    cookie: {
      key: "fig_ajs_user_id",
    },
    localStorage: {
      key: "fig_ajs_user_traits",
    },
  },
};
const customAnalyticsClient = client.getAnalytics(settings);

export const useSegment = () => {
  const identify = (ethAccount?: string) => {
    return client.identify(undefined, ethAccount, customAnalyticsClient);
  };

  const trackPage = (properties?: DAppPageEventProperties) => {
    client.page(
      {
        type: "page",
        properties,
      },
      undefined, // handle properties
      customAnalyticsClient
    );
  };

  const trackEvent = useCallback(
    (
      event: DAppTrackEvent,
      properties?: DAppTrackEventProperties | DAppNavEventProperties
    ) => {
      client.track(
        {
          event: `${event.object} | ${event.action}`,
          type: "track",
          properties,
        },
        undefined, // handle properties
        customAnalyticsClient
      );
    },
    []
  );

  const resetAnalytics = () => {
    client.reset(customAnalyticsClient);
  };

  return {
    identify,
    trackPage,
    trackEvent,
    resetAnalytics,
    getUser: client.analytics?.user,
  };
};
