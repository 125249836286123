"use client";

import React, { useState, createContext, memo } from "react";
import {
  UseWalletProviderProps,
  UseWalletProviderState,
} from "./use-wallet.types";
import { BaseNetwork } from "@figmentjs/protocols";
import {
  OneKeyWallet,
  WalletProvider,
} from "./use-bitcoin-wallet/wallet-providers";

export const UseWalletProviderContext = createContext<UseWalletProviderState>({
  isSendingTransaction: false,
  setIsSendingTransaction: () => {
    throw new Error("setIsSendingTransaction not implemented");
  },
  sendTransactionError: undefined,
  setSendTransactionError: () => {
    throw new Error("setSendTransactionError not implemented");
  },
  transactionHash: undefined,
  setTransactionHash: () => {
    throw new Error("setTransactionHash not implemented");
  },
  name: undefined,
  setName: () => {
    throw new Error("setName not implemented");
  },
  protocol: undefined,
  isTestnetMode: undefined,
  account: undefined,
  setAccount: () => {
    throw new Error("setAccount not implemented");
  },
  balance: undefined,
  setBalance: () => {
    throw new Error("setBalance not implemented");
  },
  isSupportedAddress: false,
  setIsSupportedAddress: () => {
    throw new Error("setIsSupportedAddress not implemented");
  },
  network: undefined,
  setNetwork: () => {
    throw new Error("setNetwork not implemented");
  },
  walletConfig: undefined,
  isCustomWallet: false,
  btcWallet: new OneKeyWallet(),
  setBtcWallet: () => {
    throw new Error("setBtcWallet not implemented");
  },
});

const UseWalletProvider: React.FC<UseWalletProviderProps> = ({
  children,
  protocol,
  isTestnetMode,
  walletConfig,
  isCustomWallet,
}) => {
  const [isSendingTransaction, setIsSendingTransaction] = useState(false);
  const [sendTransactionError, setSendTransactionError] = useState<string>();
  const [transactionHash, setTransactionHash] = useState<string>();
  const [name, setName] = useState<string>();
  const [account, setAccount] = useState<string>();
  const [balance, setBalance] = useState<string>();
  const [isSupportedAddress, setIsSupportedAddress] = useState(false);
  const [btcWallet, setBtcWallet] = useState<WalletProvider>(
    new OneKeyWallet()
  );
  const [network, setNetwork] = useState<BaseNetwork>();

  return (
    <UseWalletProviderContext.Provider
      value={{
        isSendingTransaction,
        setIsSendingTransaction,
        sendTransactionError,
        setSendTransactionError,
        transactionHash,
        setTransactionHash,
        name,
        setName,
        protocol,
        isTestnetMode,
        account,
        setAccount,
        balance,
        setBalance,
        isSupportedAddress,
        setIsSupportedAddress,
        network,
        setNetwork,
        walletConfig,
        isCustomWallet,
        btcWallet,
        setBtcWallet,
      }}
    >
      {children}
    </UseWalletProviderContext.Provider>
  );
};

export default memo(UseWalletProvider);
