import { PublicClient, Address } from "../../../../index";
import { Network } from "@figmentjs/protocols";
import { networksToChainsMap } from "./networks-to-chains-map";

// Encodes the function name to its ABI signature, which are the
// first 4 bytes of the sha3 hash of the function name including
// types.
const getBalanceHash = "0x70a08231000000000000000000000000";
const isAllowedHash = "0xf8350ed0000000000000000000000000";
const sharesFromUnderlyingBalanceHash = "0x799a1954000000000000000000000000";

export const getLsEthBalance = async (
  publicClient: PublicClient,
  account: Address,
  network: Network
) => {
  const response = await publicClient.call({
    account,
    to: networksToChainsMap[network]?.river,
    data: `${getBalanceHash}${account.slice(2)}`,
  });

  return response.data;
};

export const getIsAllowed = async (
  publicClient: PublicClient,
  account: Address,
  network: Network
) => {
  const response = await publicClient.call({
    account,
    to: networksToChainsMap[network]?.allowlist,
    data: `${isAllowedHash}${account.slice(2)}${
      networksToChainsMap[network]?.depositMask
    }`,
  });

  return response.data;
};

export const getLsEthConversionRate = async (
  publicClient: PublicClient,
  network: Network,
  underlyingBalance = "0000000000000000000000000de0b6b3a7640000"
) => {
  const response = await publicClient.call({
    to: networksToChainsMap[network]?.river,
    data: `${sharesFromUnderlyingBalanceHash}${underlyingBalance}`,
  });

  return response.data;
};
