import {
  getAddressBalance,
  getFundingUTXOs,
  getNetworkFees,
} from "../../utils/mempool-api";
import {
  isSupportedAddressType,
  toNetwork,
} from "../../use-bitcoin-wallet.utils";
import {
  Fees,
  Network,
  BitcoinUTXOs,
  WalletProvider,
} from "../../wallet-providers/wallet-provider.types";
import { BabylonNetwork } from "@figmentjs/protocols";

export const oneKeyProvider = "$onekey";

export class OneKeyWallet extends WalletProvider {
  public hasWallet: boolean;
  public provider?: any;

  constructor() {
    super();
    this.provider =
      typeof window !== "undefined" &&
      ((window.$onekey && window.$onekey.btc) || window.unisat);
    this.hasWallet = !!this.provider;
  }

  async isConnected(): Promise<boolean> {
    if (!this.hasWallet) {
      return false;
    }
    // If accounts are returned, the wallet is connected.
    const accounts = await this.provider?.getAccounts();
    return !!accounts?.length;
  }

  async onAccountChange(cb: (accounts: string[]) => void) {
    if (!this.hasWallet) {
      return;
    }

    this.provider.on("accountsChanged", async (accounts: string[]) => {
      cb(accounts);
    });
  }

  removeListeners() {
    if (!this.hasWallet) {
      return;
    }

    try {
      this.provider.off("accountsChanged");
    } catch (e) {
      // If no listeners are found, do nothing.
    }
  }

  async connectWallet(): Promise<this> {
    const provider = await this.provider.requestAccounts();
    return provider;
  }

  async getWalletProviderName(): Promise<string> {
    if (!this.hasWallet) {
      return "";
    }

    return "OneKey";
  }

  async getAddress(): Promise<string> {
    if (!this.hasWallet) {
      return "";
    }

    const accounts = await this.provider.getAccounts();
    return accounts[0];
  }

  async isSupportedAddress(): Promise<boolean> {
    if (!this.hasWallet) {
      return false;
    }

    const address = await this.getAddress();
    const isSupported = isSupportedAddressType(address);
    return isSupported;
  }

  async getPublicKeyHex(): Promise<string> {
    if (!this.hasWallet) {
      return "";
    }

    return this.provider.getPublicKey();
  }

  async signPsbt(psbtHex: string): Promise<string> {
    if (!this.hasWallet) {
      return "";
    }

    return this.provider.signPsbt(psbtHex);
  }

  async signMessage(message: string): Promise<string> {
    if (!this.hasWallet) {
      return "";
    }

    return this.provider.signMessage(message, "bip322-simple");
  }

  async getNetwork(): Promise<Network | undefined> {
    if (!this.hasWallet) {
      return undefined;
    }

    return this.provider.getNetwork();
  }

  on(eventName: string, callBack: () => void): void {
    if (!this.hasWallet) {
      return;
    }

    this.provider.on(eventName, callBack);
  }

  async getBalance(): Promise<number> {
    if (!this.hasWallet) {
      return 0;
    }

    const network = await this.getNetwork();
    const validNetwork = toNetwork(network);

    // mempool call
    return getAddressBalance(
      await this.getAddress(),
      validNetwork === BabylonNetwork.SIGNET
    );
  }

  async getNetworkFees(): Promise<Fees | undefined> {
    if (!this.hasWallet) {
      return;
    }

    const network = await this.getNetwork();
    const validNetwork = toNetwork(network);

    // mempool call
    return getNetworkFees(validNetwork === BabylonNetwork.SIGNET);
  }

  async pushTx(txHex: string): Promise<string> {
    if (!this.hasWallet) {
      return "";
    }

    return this.provider.pushTx(txHex);
  }

  async getUtxos({
    address,
    amount,
  }: {
    address: string;
    amount?: number;
  }): Promise<BitcoinUTXOs[]> {
    if (!this.hasWallet || !address || !amount) {
      return [];
    }

    const network = await this.getNetwork();
    const validNetwork = toNetwork(network);

    // mempool call
    return getFundingUTXOs({
      address,
      amount,
      isTestnetMode: validNetwork === BabylonNetwork.SIGNET,
    });
  }
}
