import { BabylonNetwork, Protocol } from "@figmentjs/protocols";
import { GetSendTransactionErrorProps } from "../use-wallet.utils";
import { Network } from "./wallet-providers";
import BigNumber from "bignumber.js";

const nativeSegwitAddressLength = 42;
const taprootAddressLength = 62;
const BITCOIN_TO_SATOSHI_CONVERSION = 100000000;
export const DEFAULT_STAKING_DURATION = 64000;

export const isSupportedAddressType = (address: string): boolean => {
  return (
    address.length === nativeSegwitAddressLength ||
    address.length === taprootAddressLength
  );
};

export const isTaproot = (address: string): boolean => {
  return address.length === taprootAddressLength;
};

export const getPublicKeyNoCoord = (pkHex: string): Buffer => {
  const publicKey = Buffer.from(pkHex, "hex");
  return publicKey.subarray(1, 33);
};

export const getSendTransactionError = ({
  error,
  rollbar,
  name,
}: GetSendTransactionErrorProps) => {
  if (["User rejected the request."].includes(error?.message)) {
    return "TX_CANCELED";
  }

  if (
    ["The request by this Web3 provider is timeout."].includes(error?.message)
  ) {
    return "TX_TIMEOUT";
  }

  rollbar.error(
    `sendTransaction() Error: ${error?.details || error?.message || ""}`,
    JSON.stringify(error),
    {
      walletDetails: {
        name,
        protocol: Protocol.BABYLON,
      },
    }
  );

  return JSON.stringify(error);
};

export const toNetwork = (
  network: Network | undefined
): BabylonNetwork | undefined => {
  switch (network) {
    case Network.MAINNET:
    case Network.LIVENET:
      return BabylonNetwork.MAINNET;
    case Network.TESTNET:
    case Network.SIGNET:
      return BabylonNetwork.SIGNET;
    default:
      return undefined;
  }
};

export const toSatoshi = (amount: BigNumber): BigNumber => {
  return amount.times(BITCOIN_TO_SATOSHI_CONVERSION);
};

export const toBitcoin = (amount: BigNumber): BigNumber => {
  return amount.div(BITCOIN_TO_SATOSHI_CONVERSION);
};
