import { useState, useEffect, useContext } from "react";
import BigNumber from "bignumber.js";
import { WalletNotConnectedError } from "@solana/wallet-adapter-base";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { useWalletModal } from "@solana/wallet-adapter-react-ui";
import { Transaction, LAMPORTS_PER_SOL } from "@solana/web3.js";
import { useRollbar } from "@figmentjs/rollbar-client";
import { Protocol, SolanaNetwork } from "@figmentjs/protocols";
import { UseGenericWalletReturnType as UseWalletReturnType } from "../use-wallet.types";
import { UseWalletProviderContext } from "../use-wallet-provider";
import { getSendTransactionError } from "./use-solana-wallet.utils";

export const useSolanaWallet = (): UseWalletReturnType<Protocol.SOLANA> => {
  const {
    isSendingTransaction,
    sendTransactionError,
    setSendTransactionError,
    setIsSendingTransaction,
    transactionHash,
    setTransactionHash,
    name,
    setName,
    isTestnetMode,
  } = useContext(UseWalletProviderContext);
  const [balance, setBalance] = useState<string>();
  const rollbar = useRollbar();

  const { connection } = useConnection();
  const {
    publicKey,
    connecting,
    wallet,
    disconnect,
    sendTransaction: send,
  } = useWallet();
  const { setVisible } = useWalletModal();

  useEffect(() => {
    const main = async () => {
      if (connection && publicKey) {
        const lamportBalance = await connection?.getBalance(publicKey);
        const solBalance =
          BigNumber(lamportBalance).dividedBy(LAMPORTS_PER_SOL);
        setBalance(solBalance?.toString());
      }
    };

    main();
  }, [connection, publicKey, setBalance]);

  useEffect(() => {
    const main = async () => {
      if (wallet?.adapter?.name) {
        setName(wallet?.adapter?.name);
      }
    };

    main();
  }, [wallet?.adapter?.name, name, setName]);

  const isWalletConnect = wallet?.adapter?.name === "WalletConnect";

  const sendTransaction = async ({ payload }: { payload: string }) => {
    if (!publicKey) throw new WalletNotConnectedError();

    setSendTransactionError(undefined);
    setIsSendingTransaction(true);

    try {
      const tx = Transaction.from(Buffer.from(payload, "hex"));

      const blockInfo = await connection.getLatestBlockhashAndContext();

      const signature = await send(tx, connection, {
        minContextSlot: blockInfo.context.slot,
      });

      setTransactionHash(signature);

      await connection.confirmTransaction({
        blockhash: blockInfo.value.blockhash,
        lastValidBlockHeight: blockInfo.value.lastValidBlockHeight,
        signature,
      });

      setIsSendingTransaction(false);
    } catch (error: any) {
      setIsSendingTransaction(false);
      setSendTransactionError(
        getSendTransactionError({ error, rollbar, isWalletConnect, name })
      );
    }
  };

  return {
    account: publicKey?.toString(),
    isConnecting: connecting,
    balance,
    network: isTestnetMode ? SolanaNetwork.DEVNET : SolanaNetwork.MAINNET,
    maxFeePerGas: "",
    isWalletConnect,
    sendTransaction,
    transactionHash: transactionHash,
    isSendingTransaction: isSendingTransaction,
    sendTransactionError: sendTransactionError,
    setSendTransactionError,
    setTransactionHash: setTransactionHash,
    walletName: name,
    connect: () => setVisible(true),
    disconnect,
  };
};
