import { useState, useEffect, useCallback } from "react";
import { useWeb3Modal, useWeb3ModalState } from "@web3modal/wagmi/react";
import * as Wallet from "../use-wallet";

type Props = {
  onOpen: () => void;
  onWalletConnect: () => void;
  onClose: () => void;
};

enum Status {
  NOT_INITIALIZED = "NOT_INITIALIZED",
  DISCONNECTED = "DISCONNECTED",
  CONNECTING = "CONNECTING",
  CONNECTED = "CONNECTED",
}

export const useConnectWallet = ({
  onOpen,
  onWalletConnect,
  onClose,
}: Props) => {
  const { account } = Wallet.useWallet();
  const { open: openWeb3Modal } = useWeb3Modal();
  const { open: isWeb3ModalOpen } = useWeb3ModalState();
  const [status, setStatus] = useState(Status.NOT_INITIALIZED);

  const handleOnClose = useCallback(() => {
    setStatus(Status.DISCONNECTED);
    onClose();
  }, [onClose]);

  const handleOnConnect = useCallback(() => {
    setStatus(Status.CONNECTED);
    onWalletConnect();
  }, [onWalletConnect]);

  const handleOnOpen = useCallback(() => {
    openWeb3Modal({ view: "Connect" });

    // Use a timeout to ensure that the Web3Modal is open before setting the
    // state var indicating that the Web3Modal is open.
    setTimeout(() => {
      setStatus(Status.CONNECTING);
      onOpen();
    }, 100);
  }, [onOpen, openWeb3Modal]);

  useEffect(() => {
    if (!account && !isWeb3ModalOpen && status === Status.NOT_INITIALIZED) {
      handleOnOpen();
    } else if (!isWeb3ModalOpen && status === Status.CONNECTING) {
      handleOnClose();
    } else if (account && status === Status.CONNECTING) {
      handleOnConnect();
    }
  }, [
    isWeb3ModalOpen,
    account,
    status,
    handleOnOpen,
    handleOnClose,
    handleOnConnect,
  ]);

  return null;
};
