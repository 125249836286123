"use client";

import {
  Config,
  PublicClient,
  WebSocketPublicClient,
  configureChains,
  createConfig,
} from "wagmi";
import { createWeb3Modal } from "@web3modal/wagmi/react";
import { EIP6963Connector } from "@web3modal/wagmi";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import { mainnet, holesky } from "viem/chains";
import { InjectedConnector } from "wagmi/connectors/injected";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import { SafeConnector } from "wagmi/connectors/safe";
import { FallbackTransport } from "viem";
import { Network } from "@figmentjs/protocols";
import { WALLET_CONNECT_PROJECT_ID, metadata } from "../config";

export const DATAHUB_API_KEY = process.env.NEXT_PUBLIC_DATAHUB_API_KEY;
export const MAINNET_CHAIN_ID = 1;
export const HOLESKY_CHAIN_ID = 17000;
export const MAINNET_RPC_URL = `https://ethereum-mainnet--rpc.datahub.figment.io/apikey/${DATAHUB_API_KEY}`;
export const HOLESKY_RPC_URL = `https://ethereum-holesky--rpc.datahub.figment.io/apikey/${DATAHUB_API_KEY}`;
const MAINNET_SAFE_TX_URL =
  "https://safe-transaction-mainnet.safe.global/api/v1/multisig-transactions";
const HOLESKY_SAFE_TX_URL =
  "https://gateway.holesky-safe.protofire.io/v1/chains/17000/transactions/multisig";

export const chainIdToNetworkMap: {
  [chainId: number]: Network;
} = {
  [MAINNET_CHAIN_ID]: Network.MAINNET,
  [HOLESKY_CHAIN_ID]: Network.HOLESKY,
};

export const networkToChainIdMap: {
  [key in Network]?: number;
} = {
  [Network.MAINNET]: MAINNET_CHAIN_ID,
  [Network.HOLESKY]: HOLESKY_CHAIN_ID,
};

export const chainIdToSafeTxURLMap: {
  [chainId: number]: string;
} = {
  [MAINNET_CHAIN_ID]: MAINNET_SAFE_TX_URL,
  [HOLESKY_CHAIN_ID]: HOLESKY_SAFE_TX_URL,
};

export const chainIdToNamespaceMap: {
  [chainId: number]: string;
} = {
  [MAINNET_CHAIN_ID]: "eip155:1",
  [HOLESKY_CHAIN_ID]: "eip155:17000",
};

export const chainIdToRPCMap: {
  [chainId: number]: string;
} = {
  [MAINNET_CHAIN_ID]: MAINNET_RPC_URL,
  [HOLESKY_CHAIN_ID]: HOLESKY_RPC_URL,
};

const { chains, publicClient } = configureChains(
  [mainnet, holesky],
  [
    jsonRpcProvider({
      rpc: (chain) => ({
        http: chainIdToRPCMap[chain.id],
      }),
    }),
  ]
);

export const wagmiConfig: Config<
  PublicClient<FallbackTransport>,
  WebSocketPublicClient
> = createConfig({
  autoConnect: true,
  connectors: [
    new WalletConnectConnector({
      chains,
      options: {
        projectId: WALLET_CONNECT_PROJECT_ID,
        showQrModal: false,
        metadata,
      },
    }),
    // MetaMask
    new EIP6963Connector({ chains }),
    // "Browser Wallet"
    new InjectedConnector({ chains, options: { shimDisconnect: true } }),
    new SafeConnector({
      chains,
      options: {
        allowedDomains: [/app.safe.global$/, /holesky-safe.protofire.io$/],
        debug: false,
      },
    }),
  ],
  publicClient,
});

createWeb3Modal({
  wagmiConfig,
  chains,
  projectId: WALLET_CONNECT_PROJECT_ID,
  themeVariables: {
    "--w3m-z-index": 1001,
    "--w3m-accent": "#004039",
    "--w3m-color-mix": "#004039",
  },
  themeMode: "light",
  // https://explorer.walletconnect.com/?type=wallet
  featuredWalletIds: [
    // Fireblocks
    "5864e2ced7c293ed18ac35e0db085c09ed567d67346ccb6f58a0327a75137489",
    // Ledger Live
    "19177a98252e07ddfc9af2083ba8e07ef627cb6103467ffebb3f8f4205fd7927",
  ],
});
