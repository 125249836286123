import {
  getAddressBalance,
  getFundingUTXOs,
  getNetworkFees,
  pushTx,
} from "../../utils/mempool-api";
import {
  isSupportedAddressType,
  toNetwork,
} from "../../use-bitcoin-wallet.utils";
import {
  Fees,
  Network,
  BitcoinUTXOs,
  WalletProvider,
} from "../wallet-provider.types";
import { BabylonNetwork } from "@figmentjs/protocols";
import { PostMessageType } from "../../../../../../../apps/dapp/scripts/elements/elements.types";
import { isAllowedDomainForDapp } from "../../../../../../../apps/dapp/utils/elements-domain-allowlist";

export class CustomWallet extends WalletProvider {
  public hasWallet: boolean;
  public provider?: any;

  constructor(provider?: {
    address?: string;
    network?: BabylonNetwork;
    publicKey?: string;
  }) {
    super();

    this.provider = provider;
    this.hasWallet = !!this.provider;
  }

  async isConnected(): Promise<boolean> {
    if (!this.hasWallet) {
      return false;
    }

    return !!this.provider.address;
  }

  async onAccountChange(cb: (accounts: string[]) => void) {
    if (!this.hasWallet) {
      return;
    }

    cb([this.provider.address]);
  }

  removeListeners() {}

  async connectWallet(): Promise<this> {
    return this.provider;
  }

  async getWalletProviderName(): Promise<string> {
    if (!this.hasWallet) {
      return "";
    }

    return "CustomWallet";
  }

  async getAddress(): Promise<string> {
    if (!this.hasWallet) {
      return "";
    }

    return this.provider.address;
  }

  async isSupportedAddress(): Promise<boolean> {
    if (!this.hasWallet) {
      return false;
    }

    const isSupported = isSupportedAddressType(this.provider.address);
    return isSupported;
  }

  async getPublicKeyHex(): Promise<string> {
    if (!this.hasWallet) {
      return "";
    }

    return this.provider.publicKey;
  }

  async signPsbt(psbtHex: string): Promise<string> {
    if (!this.hasWallet) {
      return "";
    }

    return new Promise((resolve, reject) => {
      const messageHandler = (event: MessageEvent) => {
        if (!isAllowedDomainForDapp(event.origin)) return;

        if (
          event.data.type === PostMessageType.FIGMENT_SIGN_TRANSACTION_RESPONSE
        ) {
          window.removeEventListener("message", messageHandler);
          resolve(event.data.signature);
        } else if (
          event.data.type === PostMessageType.FIGMENT_SIGN_TRANSACTION_ERROR
        ) {
          window.removeEventListener("message", messageHandler);
          reject(new Error(event.data.error));
        }
      };

      window.addEventListener("message", messageHandler);

      window.parent.postMessage(
        {
          type: PostMessageType.FIGMENT_SIGN_TRANSACTION,
          transaction: psbtHex,
        },
        document.referrer
      );
    });
  }

  async signMessage(message: string): Promise<string> {
    if (!this.hasWallet) {
      return "";
    }

    return new Promise((resolve, reject) => {
      const messageHandler = (event: MessageEvent) => {
        if (!isAllowedDomainForDapp(event.origin)) return;

        if (event.data.type === PostMessageType.FIGMENT_SIGN_MESSAGE_RESPONSE) {
          window.removeEventListener("message", messageHandler);
          resolve(event.data.signature);
        } else if (
          event.data.type === PostMessageType.FIGMENT_SIGN_MESSAGE_ERROR
        ) {
          window.removeEventListener("message", messageHandler);
          reject(new Error(event.data.error));
        }
      };

      window.addEventListener("message", messageHandler);

      window.parent.postMessage(
        { type: PostMessageType.FIGMENT_SIGN_MESSAGE, message },
        document.referrer
      );
    });
  }

  async getNetwork(): Promise<Network | undefined> {
    if (!this.hasWallet) {
      return undefined;
    }

    return this.provider.network === BabylonNetwork.SIGNET
      ? Network.SIGNET
      : Network.MAINNET;
  }

  on() {}

  async getBalance(): Promise<number> {
    if (!this.hasWallet) {
      return 0;
    }

    const network = await this.getNetwork();
    const validNetwork = toNetwork(network);

    // mempool call
    return getAddressBalance(
      await this.getAddress(),
      validNetwork === BabylonNetwork.SIGNET
    );
  }

  async getNetworkFees(): Promise<Fees | undefined> {
    if (!this.hasWallet) {
      return;
    }

    const network = await this.getNetwork();
    const validNetwork = toNetwork(network);
    const isTestnetMode = validNetwork === BabylonNetwork.SIGNET;

    // mempool call
    return getNetworkFees(isTestnetMode);
  }

  async pushTx(txHex: string): Promise<string> {
    if (!this.hasWallet) {
      return "";
    }

    const network = await this.getNetwork();
    const validNetwork = toNetwork(network);
    const isTestnetMode = validNetwork === BabylonNetwork.SIGNET;

    // mempool call
    return pushTx(txHex, isTestnetMode);
  }

  async getUtxos({
    address,
    amount,
  }: {
    address: string;
    amount?: number;
  }): Promise<BitcoinUTXOs[]> {
    if (!this.hasWallet || !address || !amount) {
      return [];
    }

    const network = await this.getNetwork();
    const validNetwork = toNetwork(network);

    // mempool call
    return getFundingUTXOs({
      address,
      amount,
      isTestnetMode: validNetwork === BabylonNetwork.SIGNET,
    });
  }
}
