export enum UniversalWidgetObjectEnum {
  STAKING_FLOW = "Staking Flow",
  UNSTAKING_FLOW = "Unstaking Flow",
}

export enum UniversalWidgetActionEnum {
  STEP_RENDERED = "Step Rendered",
  UNSTAKING_FEEDBACK_SUBMITTED = "Unstaking Feedback Submitted",
  CONFIRM_STAKE_BTN_CLICKED = "Confirm Stake Button Clicked",
}
