import { AnalyticsBrowser, SegmentEvent } from "@segment/analytics-next";
import {
  Traits,
  TrackEvent,
  Properties,
  SegmentSettings,
  GroupTraits,
} from "../types";

export * from "../types";
export * from "../apps";

export const injectMiddleware = (customAnalyticsClient: AnalyticsBrowser) => {
  customAnalyticsClient.addSourceMiddleware(async ({ payload, next }) => {
    const eventType = payload.obj.type;

    if (eventType === "track") {
      const user = await customAnalyticsClient.user();
      const traits = user.traits();

      payload.obj.properties = {
        ...payload.obj.properties,
        orgId: traits?.orgId,
        userId: traits?.userId,
      };
    }

    next(payload);
  });
};

const getAnalytics = (
  settings?: SegmentSettings,
  writeKeyOverride?: string
) => {
  return AnalyticsBrowser.load(
    {
      writeKey:
        writeKeyOverride || process.env.NEXT_PUBLIC_SEGMENT_ACCESS_TOKEN!,
    },
    {
      ...settings,
    }
  );
};

// Default Analytics Client
const analytics = getAnalytics();

const identify = async (
  traits?: Traits,
  userId?: string,
  customAnalyticsClient?: AnalyticsBrowser
) => {
  const analyticsClient = customAnalyticsClient ?? analytics;
  try {
    await analyticsClient.identify(userId, traits);
    return true;
  } catch {
    return false;
  }
};

const track = async (
  event: SegmentEvent | TrackEvent,
  properties?: Properties,
  customAnalyticsClient?: AnalyticsBrowser
) => {
  const analyticsClient = customAnalyticsClient ?? analytics;
  try {
    await analyticsClient.track(event, properties);
    return true;
  } catch {
    return false;
  }
};

const page = async (
  event: SegmentEvent | TrackEvent,
  properties?: Properties,
  customAnalyticsClient?: AnalyticsBrowser
) => {
  const analyticsClient = customAnalyticsClient ?? analytics;
  try {
    await analyticsClient.page(event, properties);
    return true;
  } catch {
    return false;
  }
};

const group = async (
  traits?: GroupTraits,
  groupId?: string,
  customAnalyticsClient?: AnalyticsBrowser
) => {
  const analyticsClient = customAnalyticsClient ?? analytics;
  try {
    await analyticsClient.group(groupId, traits);
    return true;
  } catch {
    return false;
  }
};

const reset = async (customAnalyticsClient?: AnalyticsBrowser) => {
  const analyticsClient = customAnalyticsClient ?? analytics;
  try {
    await analyticsClient.reset();
    return true;
  } catch {
    return false;
  }
};

/**
 * The client analytics object.
 */
export const client = {
  analytics,
  identify,
  track,
  page,
  group,
  reset,
  getAnalytics,
};
