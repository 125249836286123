import { Network } from "@figmentjs/protocols";

type ChainConfig = {
  river: `0x${string}`;
  allowlist: `0x${string}`;
  depositMask?: string;
};

const depositMask =
  "0000000000000000000000000000000000000000000000000000000000000001";

export const networksToChainsMap: {
  [key in Network]?: ChainConfig;
} = {
  [Network.MAINNET]: {
    // LsETH token contract - we can both deposit to and read data from it
    river: "0x8c1BEd5b9a0928467c9B1341Da1D7BD5e10b6549",
    // Allowlist contract
    allowlist: "0xebc83Bb472b2816Ec5B5de8D34F0eFc9088BB2ce",
    depositMask,
  },
  [Network.GOERLI]: {
    river: "0x3ecCAdA3e11c1Cc3e9B5a53176A67cc3ABDD3E46",
    allowlist: "0xe7B74d98D46A8e0979B0342172A3A4890F852558",
    depositMask,
  },
  [Network.HOLESKY]: {
    river: "0x1d8b30cC38Dba8aBce1ac29Ea27d9cFd05379A09",
    allowlist: "0x5C783DCD596dad2bDe930f22C3684E77E25b6436",
    depositMask,
  },
};
