import { useContext } from "react";
import { ProtocolNetworkMap, Protocol } from "@figmentjs/protocols";
import { useEthereumWallet } from "./use-ethereum-wallet";
import { useSolanaWallet } from "./use-solana-wallet";
import { useBitcoinWallet } from "./use-bitcoin-wallet";
import {
  UseBitcoinWalletReturnType,
  UseGenericWalletReturnType,
} from "./use-wallet.types";
import { UseWalletProviderContext } from "./use-wallet-provider";

export type UseWalletReturnType<T extends keyof ProtocolNetworkMap> =
  T extends Protocol.BABYLON
    ? UseBitcoinWalletReturnType<T>
    : UseGenericWalletReturnType<T>;

export const useWallet = <
  T extends keyof ProtocolNetworkMap
>(): UseWalletReturnType<T> => {
  const { protocol } = useContext(UseWalletProviderContext);

  switch (protocol) {
    case Protocol.ETHEREUM:
      // eslint-disable-next-line react-hooks/rules-of-hooks
      return useEthereumWallet() as UseWalletReturnType<T>;
    case Protocol.SOLANA:
      // eslint-disable-next-line react-hooks/rules-of-hooks
      return useSolanaWallet() as UseWalletReturnType<T>;
    case Protocol.BABYLON:
      // eslint-disable-next-line react-hooks/rules-of-hooks
      return useBitcoinWallet() as UseWalletReturnType<T>;
    case Protocol.INJECTIVE:
      return {} as UseWalletReturnType<T>;
    default:
      // eslint-disable-next-line react-hooks/rules-of-hooks
      return useEthereumWallet() as UseWalletReturnType<T>;
  }
};
