import { useEffect } from "react";
import {
  parseEther,
  formatEther,
  parseTransaction,
  http,
  createPublicClient,
} from "viem";
import { usePrepareSendTransaction, useFeeData } from "wagmi";
import Rollbar from "@figmentjs/rollbar-client/src/config";
import { Network, Protocol } from "@figmentjs/protocols";
import {
  MAINNET_CHAIN_ID,
  chainIdToRPCMap,
  chainIdToSafeTxURLMap,
  networkToChainIdMap,
} from "./../web3modal/ethereum/ethereum.web3modal.config";
import {
  GetSendTransactionParams,
  CustomPublicClient,
  UseConfirmTransactionParams,
  GetClient,
  GetTransactionHash,
} from "./use-wallet.types";

export const SAFE_CONNECTOR_NAME = "Safe";

const getClient: GetClient = ({ chain }) => {
  const transport = http(chainIdToRPCMap[chain!.id]);

  return createPublicClient({
    chain,
    transport,
  }) as CustomPublicClient;
};

const CANCELLED_TX_MESSAGES = [
  // MetaMask
  "MetaMask Tx Signature: User denied transaction signature.",
  // WalletConnect - Standard
  "User rejected methods",
  // WalletConnect - Ledger Live
  "Transaction declined",
  // WalletConnect - Fireblocks
  "User rejected methods.",
  // WalletConnect - Fireblocks
  "User rejected.",
  // WalletConnect - MetaMask
  "User rejected the transaction",
  // Unknown, but not WalletConnect
  "Rejected by user",
  // Safe Wallet
  "Transaction was rejected",
];

export type GetSendTransactionErrorProps = {
  error: any;
  rollbar: Rollbar;
  isWalletConnect?: boolean;
  name?: string;
  isWaitTxError?: boolean;
};

export const getSendTransactionError = ({
  error,
  rollbar,
  isWalletConnect,
  name,
  isWaitTxError,
}: GetSendTransactionErrorProps) => {
  if (
    // Global
    error?.cause?.code === 4001 ||
    // This covers TXs sent with wagmi
    CANCELLED_TX_MESSAGES.includes(error?.details) ||
    // This covers TXs sent with the WalletConnect signer
    CANCELLED_TX_MESSAGES.includes(error?.message) ||
    // dApp - Ledger Live
    error?.reason?.includes("Transaction declined") ||
    // dApp - Ledger Live Dapp Browser V3
    error?.details?.includes("Transaction declined")
  ) {
    return "TX_CANCELED";
  } else if (
    // WalletConnect tx timeout
    JSON.stringify(error) === "{}"
  ) {
    return "TX_TIMEOUT";
  } else if (error?.details === "Request expired. Please try again.") {
    return "TX_EARLY_EXPIRATION";
  } else {
    rollbar.error(
      `${isWaitTxError ? "waitForTransaction" : "sendTransaction"}() Error: ${
        error?.details || error?.message || ""
      }`,
      JSON.stringify(error),
      {
        walletDetails: {
          isWalletConnect,
          name,
          protocol: Protocol.ETHEREUM,
        },
      }
    );

    return JSON.stringify(error);
  }
};

export const getSendTransactionParams = ({
  account,
  to,
  amount,
  payload,
}: GetSendTransactionParams) => {
  const value = parseEther(amount);

  if (payload) {
    const tx = parseTransaction(payload);

    return {
      from: account,
      to: tx.to!,
      value,
      data: tx.data,
      gas: tx.gas,
    };
  }

  return {
    from: account,
    to: to!,
    value,
  };
};

export const useConfirmTransaction = async ({
  waitTxData,
  setSendTransactionError,
  setIsSendingTransaction,
  chain,
  hash,
}: UseConfirmTransactionParams) => {
  useEffect(() => {
    const main = async () => {
      try {
        if (waitTxData?.status && waitTxData?.blockNumber) {
          if (waitTxData?.status !== "success") {
            setSendTransactionError("TX_NOT_SUCCESSFUL");
            setIsSendingTransaction(false);
            return;
          }

          const client = getClient({ chain });

          const interval = setInterval(async () => {
            const block = await client.getBlock({
              blockNumber: waitTxData.blockNumber,
            });

            if (block && block.transactions.includes(hash!)) {
              clearInterval(interval);

              // At this point, we can be sure that the transaction was successful.
              setIsSendingTransaction(false);
            }
          }, 1000);
        }
      } catch (error: any) {
        setIsSendingTransaction(false);
        setSendTransactionError(JSON.stringify(error));
        throw error;
      }
    };

    main();
  }, [
    waitTxData?.status,
    waitTxData?.blockNumber,
    hash,
    chain,
    setIsSendingTransaction,
    setSendTransactionError,
  ]);
};

const fetchTxBySafeTxHash = async (
  hash?: `0x${string}`,
  chainId?: number,
  address?: string
) => {
  const url = chainIdToSafeTxURLMap[chainId!];

  const isMainnet = chainId === MAINNET_CHAIN_ID;

  const requestUrl = isMainnet ? `${url}/${hash}` : `${url}_${address}_${hash}`;

  const response = await fetch(requestUrl);
  const json = await response.json();

  return {
    safeTxHash: isMainnet
      ? json?.safeTxHash
      : json?.detailedExecutionInfo?.safeTxHash,
    transactionHash: isMainnet ? json?.transactionHash : json?.txHash,
  };
};

export const fetchAndSetTransactionHash: GetTransactionHash = async ({
  hash,
  chain,
  setTransactionHash,
  name,
  connectorName,
  address,
}) => {
  // If the connected wallet is Safe wallet, we need to fetch the real transaction hash.
  if (name === "Safe{Wallet}" || connectorName === SAFE_CONNECTOR_NAME) {
    const transaction = await fetchTxBySafeTxHash(hash, chain?.id, address);

    if (transaction?.safeTxHash === hash) {
      // Now, we can be positive that the user was using Safe wallet to send the transaction,
      // and we need to wait until the real transaction hash is available.

      const interval = setInterval(async () => {
        const transaction = await fetchTxBySafeTxHash(hash, chain?.id, address);

        if (transaction.transactionHash) {
          clearInterval(interval);
          setTransactionHash(transaction.transactionHash);
        }
      }, 1000);
    }

    // At this point, we know that the account is a contract, but it is not a Safe wallet account.
    // This is where we'd put additional logic for handling other multisig wallets.
  } else {
    setTransactionHash(hash);
  }
};

type UseTransactionFeeProps = {
  account: `0x${string}`;
  network: Network | undefined;
  payload?: `0x${string}`;
  amount?: string;
};

export const useTransactionFee = ({
  account,
  network,
  payload,
  amount = "0",
}: UseTransactionFeeProps) => {
  const { data: feeData } = useFeeData();

  const params = getSendTransactionParams({ account, payload, amount });

  const {
    data: prepareSendTxData,
    isLoading,
    error,
  } = usePrepareSendTransaction({
    account,
    chainId: network && networkToChainIdMap[network],
    maxFeePerGas: feeData?.maxFeePerGas || undefined,
    data: params.data,
    to: params.to,
    value: params.value,
  });

  return {
    isLoading,
    error,
    fee: formatEther(
      (prepareSendTxData?.gas || BigInt(0)) *
        (prepareSendTxData?.maxFeePerGas || BigInt(0))
    ),
  };
};

type IsIframeParams = {
  enforceEmbedParam?: boolean;
};

export const isIframe = ({
  enforceEmbedParam = true,
}: IsIframeParams): boolean => {
  try {
    const params = new URLSearchParams(window.self.location.search);
    const isLedger = window.ethereum?.isLedgerLive;
    const isEmbed = !!params.get("embed");

    if (isLedger) {
      if (enforceEmbedParam) {
        return isEmbed;
      }
      return true;
    }

    return window.self !== window.top && (enforceEmbedParam ? isEmbed : true);
  } catch (error) {
    return false;
  }
};
