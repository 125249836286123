export const metadata = {
  name: "Figment",
  description: "Earn rewards while staking ETH with Figment",
  url:
    typeof window === "undefined"
      ? "https://app.figment.io/"
      : window.location.origin,
  icons: ["https://app.figment.io/images/figment-logo.png"],
};

const projectId = process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID;

if (!projectId) {
  throw new Error("NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID is not set");
}

export const WALLET_CONNECT_PROJECT_ID = projectId;
