import BigNumber from "bignumber.js";
import { Psbt } from "bitcoinjs-lib";
import { useState, useEffect, useContext, useCallback } from "react";
import { useRollbar } from "@figmentjs/rollbar-client";
import { BabylonNetwork, BaseNetwork, Protocol } from "@figmentjs/protocols";
import { WalletNotConnectedError } from "@solana/wallet-adapter-base";
import { UseWalletProviderContext } from "../use-wallet-provider";
import { CustomWallet, WalletProvider } from "./wallet-providers";
import {
  getPublicKeyNoCoord,
  getSendTransactionError,
  toNetwork,
  toBitcoin,
} from "./use-bitcoin-wallet.utils";
import { UseBitcoinWalletReturnType } from "../use-wallet.types";
import { useCurrentWalletAddress } from "../../use-current-eth-address";

export const useBitcoinWallet =
  (): UseBitcoinWalletReturnType<Protocol.BABYLON> => {
    const rollbar = useRollbar();
    const {
      isSendingTransaction,
      sendTransactionError,
      setSendTransactionError,
      setIsSendingTransaction,
      transactionHash,
      setTransactionHash,
      name,
      setName,
      isTestnetMode,
      account,
      setAccount,
      balance,
      setBalance,
      isSupportedAddress,
      setIsSupportedAddress,
      network,
      setNetwork,
      walletConfig,
      isCustomWallet,
      btcWallet,
      setBtcWallet,
    } = useContext(UseWalletProviderContext);

    const [isConnecting, setIsConnecting] = useState(false);
    const [pkNoCoord, setPkNoCoord] = useState("");

    const { setCurrentWalletAddress } = useCurrentWalletAddress();

    const resetWalletInfo = useCallback(() => {
      setAccount();
      setBalance();
      setPkNoCoord("");
      setIsSupportedAddress(false);
      setSendTransactionError(undefined);
      setTransactionHash(undefined);
    }, [setSendTransactionError, setTransactionHash]);

    const updateWalletInfo = useCallback(
      async (walletProvider: WalletProvider) => {
        const isSupportedAddress = await btcWallet.isSupportedAddress();
        const address = await walletProvider.getAddress();
        const pkhex = await walletProvider.getPublicKeyHex();
        const publicKeyNoCoord = getPublicKeyNoCoord(pkhex);
        const balance = await walletProvider.getBalance();
        const walletName = await walletProvider.getWalletProviderName();
        const btcNetwork = await walletProvider.getNetwork();

        setIsSupportedAddress(isSupportedAddress);
        setAccount(address);
        setBalance(BigNumber(balance).toString());
        setPkNoCoord(publicKeyNoCoord.toString("hex"));
        setNetwork(toNetwork(btcNetwork) as unknown as BaseNetwork);
        setName(walletName);
      },
      [btcWallet, setName]
    );

    const sendTransaction = async ({ payload }: { payload: string }) => {
      if (!account || !isSupportedAddress) throw new WalletNotConnectedError();

      setSendTransactionError(undefined);
      setIsSendingTransaction(true);

      try {
        const signedHex = await btcWallet.signPsbt(payload);
        const signedTransaction = Psbt.fromHex(signedHex).extractTransaction();

        const txID = await btcWallet.pushTx(signedTransaction.toHex());
        setTransactionHash(txID);
      } catch (error) {
        setSendTransactionError(
          getSendTransactionError({ error, rollbar, name })
        );
      } finally {
        setIsSendingTransaction(false);
      }
    };

    const signMessage = async ({ message }: { message: string }) => {
      if (!account || !isSupportedAddress) throw new WalletNotConnectedError();

      setSendTransactionError(undefined);
      setIsSendingTransaction(true);

      let signature;

      try {
        signature = await btcWallet.signMessage(message);
      } catch (error) {
        setSendTransactionError(
          getSendTransactionError({ error, rollbar, name })
        );
      } finally {
        setIsSendingTransaction(false);
      }

      return signature;
    };

    const connect = useCallback(
      async (walletProvider: WalletProvider) => {
        try {
          resetWalletInfo();
          setIsConnecting(true);
          await walletProvider.connectWallet();
          updateWalletInfo(walletProvider);
        } catch (error) {
          rollbar.error(error as Error);
          resetWalletInfo();
        } finally {
          setIsConnecting(false);
        }
      },
      [resetWalletInfo, rollbar, updateWalletInfo]
    );

    const handleAccountChange = useCallback(
      async (accounts: string[]) => {
        if (!accounts.length) {
          // If there are no accounts, the wallet was disconnected.
          resetWalletInfo();
        } else {
          // If there are accounts, re-connect the wallet with the new account.
          connect(btcWallet);
        }
      },
      [btcWallet, connect, resetWalletInfo]
    );

    // TODO: this can be merged with the useEffect above, just need to test the constructor with no walletConfig.
    useEffect(() => {
      if (isCustomWallet && walletConfig) {
        setBtcWallet(new CustomWallet(JSON.parse(walletConfig)));
      }
    }, [isCustomWallet, walletConfig, setBtcWallet]);

    useEffect(() => {
      setIsConnecting(true);
      const main = async () => {
        if (await btcWallet.isConnected()) {
          connect(btcWallet);
        }
        btcWallet.onAccountChange(handleAccountChange);
        setIsConnecting(false);
      };
      main();

      return () => {
        btcWallet.removeListeners();
      };
    }, [btcWallet, connect, handleAccountChange]);

    useEffect(() => {
      setCurrentWalletAddress(account);
    }, [account]);

    return {
      account,
      publicKeyNoCoord: pkNoCoord,
      isSupportedAddress,
      isConnecting,
      network: network as unknown as BabylonNetwork,
      balance: toBitcoin(BigNumber(balance || 0)).toString(),
      sendTransaction,
      transactionHash,
      isSendingTransaction,
      sendTransactionError,
      setTransactionHash,
      setSendTransactionError,
      walletName: name,
      connect: () => connect(btcWallet),
      disconnect: btcWallet.disconnectWallet,
      hasSupportedWallet: btcWallet.hasWallet,
      getUTXOs: (amount: number) =>
        btcWallet.getUtxos({ address: account!, amount }),
      // Bitcoin wallets can be on different networks than what the app is expecting,
      // so this gives components a way to know which network the app is expecting.
      displayedNetwork: isTestnetMode
        ? BabylonNetwork.SIGNET
        : BabylonNetwork.MAINNET,
      signMessage,
    };
  };
