export { useWeb3Modal, useWeb3ModalState } from "@web3modal/wagmi/react";

export * from "./src/use-wallet";
export * from "./src/use-current-eth-address";
export { LiquidStakingProvider } from "./src/use-wallet/use-ethereum-wallet/use-liquid-staking";
import { useLiquidStaking } from "./src/use-wallet/use-ethereum-wallet/use-liquid-staking";

import { useWallet } from "./src/use-wallet";
import { useConnectWallet } from "./src/use-connect-wallet/use-connect-wallet";

type WalletType = {
  useWallet: typeof useWallet;
  useConnectWallet: typeof useConnectWallet;
  useLiquidStaking: typeof useLiquidStaking;
};

const Wallet: WalletType = {
  useWallet,
  useConnectWallet,
  useLiquidStaking,
};

export default Wallet;
