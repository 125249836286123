/**
 * A FigAppObject describes a component for which we're tracking actions
 * in combination with a FigAppAction
 * `${FigAppObject} | ${FigAppAction`
 * Read more about this practice here: https://segment.com/docs/getting-started/04-full-install/#event-naming-best-practices
 **/

export enum FigAppObjectsEnum {
  IMPORT_POSITION_MODAL = "Import Position Modal",
  FIGMENT_TERMS_OF_USE = "Figment Terms Of Use",
  FINANCIAL_STATEMENTS_TABLE = "Financial Statements Table",
  LIQUID_COLLECTIVE_TERMS_OF_USE = "Liquid Collective Terms Of Use",
  LIQUID_COLLECTIVE_TX_EXPLORER_URL = "Liquid Collective Tx Explorer Url",
  REWARDS_EARNED_CHART = "Rewards Earned Chart",
  REWARDS_PAYOUTS = "Rewards Payouts",
  REPORTS_TABS = "Reports Page Tabs",
  SELECT_WALLET_MODAL = "Select Wallet Modal",
  SIGN_UP = "Sign Up Form",
  STAKING_MODAL = "Staking Modal",
  EIGENLAYER_RESTAKING_MODAL = "EigenLayer Restaking Modal",
  UNSTAKING_MODAL = "Unstaking Modal",
  STAKING_POSITIONS_TABLE = "Staking Positions Table",
  STAKING_POSITION_MODAL = "Staking Position Modal",
  STAKING_WIDGET = "Staking Widget",
  IDLE_TIMER = "Idle Timer",
  ADD_MFA_AUTHENTICATION_MODAL = "Add MFA Authentication Modal",
  TESTNET_MODE_TOGGLE = "Testnet Mode Toggle",
  EMPTY_DASHBOARD_GUIDE_ITEM = "Empty Dashboard Guide Item",
  RESTAKING_MODE_TOGGLE = "Restaking Mode Toggle",
  LS_ETH_REDEMPTION_MODAL = "LsEth Redemption Modal",
  LS_ETH_CLAIM_MODAL = "LsEth Claim Modal",
  UNDELEGATE_SOLANA_MODAL = "Undelegate Solana Modal",
  WITHDRAW_SOLANA_MODAL = "Withdraw Solana Modal",
  CREATE_VAULT_WIZARD = "Create Vault Wizard",
}

export enum FigAppActionEnum {
  DETAILS_BTN_CLICKED = "Details Button Clicked",
  DOWNLOAD_CSV_BUTTON = "Download CSV Button Clicked",
  DURATION_TOGGLED = "Duration Toggled",
  FORGOT_PASSWORD_BUTTON_CLICKED = "Forgot Password button clicked",
  FORM_SUBMITTED = "Form Submitted",
  LEGAL_CHECKBOX_TOGGLED = "Legal Checkbox Toggled",
  LINK_CLICKED = "Link Clicked",
  MODAL_OPENED = "Modal Opened",
  MODAL_CLOSED = "Modal Closed",
  PREPARE_DOWNLOAD_BTN_CLICKED = "Prepare Download Button Clicked",
  STAKE_BTN_CLICKED = "Stake Button Clicked",
  UNDELEGATE_BTN_CLICKED = "Undelegate Button Clicked",
  WITHDRAW_BTN_CLICKED = "Withdraw Button Clicked",
  TAB_CHANGED = "Tab Changed",
  TABLE_ROW_EXPANDED = "Table Row Expanded",
  WALLET_CONNECTED = "Wallet Connected",
  WALLET_DISCONNECTED = "Wallet Disconnected",
  CONFIRM_STAKE_BTN_CLICKED = "Confirm Stake Button Clicked",
  CONFIRM_UNSTAKE_BTN_CLICKED = "Confirm Unstake Button Clicked",
  STEP_RENDERED = "Step Rendered",
  LOGOUT_DUE_TO_INACTIVITY = "Logout Due To Inactivity",
  TOGGLE_ON = "Toggle On",
  TOGGLE_OFF = "Toggle Off",
  MORE_OPTIONS_TOGGLED = "More options toggled",
  LOCATION_CHANGED = "Validator location changed",
  EIGENPOD_DEPLOYED = "EigenPod Deployed",
  DEPLOY_EIGENPOD_BTN_CLICKED = "Deploy Eigenpod Button Clicked",
  ADDRESS_CHANGED = "Address Changed",
  TRY_AGAIN_BTN_CLICKED = "Try Again Button Clicked",
  UNDELEGATE_TRANSACTION_CANCELLED = "Undelegate Transaction Cancelled",
  UNDELEGATE_TRANSACTION_SUCCEEDED = "Undelegate Transaction Succeeded",
  WITHDRAW_TRANSACTION_SUCCEEDED = "Withdraw Transaction Succeeded",
  WITHDRAW_TRANSACTION_CANCELLED = "Withdraw Transaction Cancelled",
  IMPORT_POSITION_BTN_CLICKED = "Import Position Button Clicked",
  POSITION_IMPORTED = "Position Imported",
  DAILY_REWARDS_LINK_CLICKED = "Daily Rewards Link Clicked",
  EXPORT_CSV_CLICKED = "Export CSV Rewards",
  EXPORT_JSON_CLICKED = "Export JSON Rewards",
}
