"use client";

import { useEffect, useState } from "react";
import { useLocalStorage } from "usehooks-ts";

export const CURRENT_WALLET_ADDRESS_STORAGE_KEY = "currentWalletAddress";

export const useCurrentWalletAddress = () => {
  const [isClient, setIsClient] = useState(false);

  // https://nextjs.org/docs/messages/react-hydration-error#solution-1-using-useeffect-to-run-on-the-client-only
  // We need to make sure that we're "on the client" before we use `currentWalletAddress`, we get hydration errors otherwise.
  useEffect(() => {
    setIsClient(true);
  }, []);

  const [currentWalletAddress, setCurrentWalletAddress] = useLocalStorage<
    string | undefined
  >(CURRENT_WALLET_ADDRESS_STORAGE_KEY, undefined);

  return {
    currentWalletAddress: isClient ? currentWalletAddress : undefined,
    setCurrentWalletAddress,
  };
};
