// TODO #1: support custom theming
// TODO #2: support custom text

import { Protocol } from "@figmentjs/protocols";

export enum Element {
  DAPP = "DAPP",
  STAKING = "STAKING",
}

export type CustomWalletConfig = {
  address: string;
  publicKey: string;
  signTransaction: (transaction: string) => Promise<string>;
  signMessage: (message: string) => Promise<string>;
};

type ElementConfig = {
  element: Element;
  containerSelector: string;
  dappToken: string;
  protocol: Protocol;
};

type ElementConfigWithWallet = ElementConfig & {
  wallet: CustomWalletConfig;
  network: string; // network is required when wallet is provided
};

type ElementConfigWithoutWallet = ElementConfig & {
  wallet?: undefined;
  network?: string; // network is optional when wallet is not provided
};

export type FIGMENT_ELEMENTS_CONFIG = {
  elements: (ElementConfigWithWallet | ElementConfigWithoutWallet)[];
  isTestnetMode?: boolean;
};

export enum PostMessageType {
  FIGMENT_SIGN_MESSAGE = "FIGMENT_SIGN_MESSAGE",
  FIGMENT_SIGN_MESSAGE_RESPONSE = "FIGMENT_SIGN_MESSAGE_RESPONSE",
  FIGMENT_SIGN_MESSAGE_ERROR = "FIGMENT_SIGN_MESSAGE_ERROR",
  FIGMENT_SIGN_TRANSACTION = "FIGMENT_SIGN_TRANSACTION",
  FIGMENT_SIGN_TRANSACTION_RESPONSE = "FIGMENT_SIGN_TRANSACTION_RESPONSE",
  FIGMENT_SIGN_TRANSACTION_ERROR = "FIGMENT_SIGN_TRANSACTION_ERROR",
  FIGMENT_PARENT_READY = "FIGMENT_PARENT_READY",
  FIGMENT_IFRAME_READY = "FIGMENT_IFRAME_READY",
}
