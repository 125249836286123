const elementsDomainAllowlist = [
  "http://localhost:*",
  "https://localhost:*",
  "http://127.0.0.1:*",
  "https://127.0.0.1:*",
  "https://figment-elements.vercel.app/",
  "https://app.dfns.ninja/",
];

const isAllowedDomainForDapp = (origin) => {
  if (
    elementsDomainAllowlist.includes(origin) ||
    origin.indexOf("http://localhost") === 0 ||
    origin.indexOf("https:/localhost") === 0 ||
    origin.indexOf("http:///127.0.0.1") === 0 ||
    origin.indexOf("https://127.0.0.1") === 0
  ) {
    return true;
  }
  return false;
};

if (typeof module !== "undefined" && module.exports) {
  // CommonJS export
  module.exports = {
    elementsDomainAllowlist,
    isAllowedDomainForDapp,
  };
}

if (typeof exports !== "undefined") {
  // ES6 module export
  exports.elementsDomainAllowlist = elementsDomainAllowlist;
  exports.isAllowedDomainForDapp = isAllowedDomainForDapp;
}
