import { Fees, BitcoinUTXOs } from "../wallet-providers";

type NetworkConfig = {
  coinName: string;
  coinSymbol: string;
  networkName: string;
  mempoolApiUrl: string;
};

const signetConfig: NetworkConfig = {
  coinName: "Signet BTC",
  coinSymbol: "sBTC",
  networkName: "BTC signet",
  mempoolApiUrl: `https://mempool.space/signet`,
};

const mainnetConfig: NetworkConfig = {
  coinName: "BTC",
  coinSymbol: "BTC",
  networkName: "BTC",
  mempoolApiUrl: `https://mempool.space`,
};

/*
    URL Construction methods
*/
// The base URL for the signet API
// Utilises an environment variable specifying the mempool API we intend to
// utilise
const mempoolSignetAPI = `${signetConfig.mempoolApiUrl}/api/`;
const mempoolLivenetAPI = `${mainnetConfig.mempoolApiUrl}/api/`;

// URL for the address info endpoint
const addressInfoUrl = (address: string, isTestnetMode: boolean): URL => {
  if (isTestnetMode) {
    return new URL(mempoolSignetAPI + "address/" + address);
  }

  return new URL(mempoolLivenetAPI + "address/" + address);
};

// URL for the push transaction endpoint
const pushTxUrl = (isTestnetMode: boolean): URL => {
  return new URL((isTestnetMode ? mempoolSignetAPI : mempoolLivenetAPI) + "tx");
};

// URL for retrieving information about an address' UTXOs
const utxosInfoUrl = (address: string, isTestnetMode: boolean): URL => {
  return new URL(
    (isTestnetMode ? mempoolSignetAPI : mempoolLivenetAPI) +
      "address/" +
      address +
      "/utxo"
  );
};

// URL for retrieving information about the recommended network fees
const networkFeesUrl = (isTestnetMode: boolean): URL => {
  return new URL(
    (isTestnetMode ? mempoolSignetAPI : mempoolLivenetAPI) +
      "v1/fees/recommended"
  );
};

// URL for validating an address which contains a set of information about the address
// including the scriptPubKey
const validateAddressUrl = (address: string, isTestnetMode: boolean): URL => {
  return new URL(
    (isTestnetMode ? mempoolSignetAPI : mempoolLivenetAPI) +
      "v1/validate-address/" +
      address
  );
};

/**
 * Pushes a transaction to the Bitcoin network.
 * @param txHex - The hex string corresponding to the full transaction.
 * @returns A promise that resolves to the response message.
 */
export const pushTx = async (
  txHex: string,
  isTestnetMode: boolean
): Promise<string> => {
  const response = await fetch(pushTxUrl(isTestnetMode), {
    method: "POST",
    body: txHex,
  });
  if (!response.ok) {
    try {
      const mempoolError = await response.text();
      // Extract the error message from the response
      const message = mempoolError.split('"message":"')[1].split('"}')[0];
      if (mempoolError.includes("error") || mempoolError.includes("message")) {
        throw new Error(message);
      } else {
        throw new Error("Error broadcasting transaction. Please try again");
      }
    } catch (error) {
      throw new Error((error as Error)?.message || error!.toString());
    }
  } else {
    return await response.text();
  }
};

/**
 * Returns the balance of an address.
 * @param address - The Bitcoin address in string format.
 * @returns A promise that resolves to the amount of satoshis that the address
 *          holds.
 */
export const getAddressBalance = async (
  address: string,
  isTestnetMode: boolean
): Promise<number> => {
  const response = await fetch(addressInfoUrl(address, isTestnetMode || false));
  if (!response.ok) {
    const err = await response.text();
    throw new Error(err);
  } else {
    const addressInfo = await response.json();
    return (
      addressInfo.chain_stats.funded_txo_sum -
      addressInfo.chain_stats.spent_txo_sum
    );
  }
};

/**
 * Retrieve the recommended Bitcoin network fees.
 * @returns A promise that resolves into a `Fees` object.
 */
export const getNetworkFees = async (isTestnetMode: boolean): Promise<Fees> => {
  const response = await fetch(networkFeesUrl(isTestnetMode));
  if (!response.ok) {
    const err = await response.text();
    throw new Error(err);
  } else {
    return await response.json();
  }
};

/**
 * Retrieve a set of UTXOs that are available to an address
 * and satisfy the `amount` requirement if provided. Otherwise, fetch all UTXOs.
 * The UTXOs are chosen based on descending amount order.
 * @param address - The Bitcoin address in string format.
 * @param amount - The amount we expect the resulting UTXOs to satisfy.
 * @returns A promise that resolves into a list of UTXOs.
 */
export const getFundingUTXOs = async ({
  address,
  amount,
  isTestnetMode,
}: {
  address: string;
  amount?: number;
  isTestnetMode?: boolean;
}): Promise<BitcoinUTXOs[]> => {
  // Get all UTXOs for the given address

  let utxos = null;
  try {
    const response = await fetch(utxosInfoUrl(address, isTestnetMode || false));
    utxos = await response.json();
  } catch (error) {
    throw new Error((error as Error)?.message || error!.toString());
  }

  // Remove unconfirmed UTXOs as they are not yet available for spending
  // and sort them in descending order according to their value.
  // We want them in descending order, as we prefer to find the least number
  // of inputs that will satisfy the `amount` requirement,
  // as less inputs lead to a smaller transaction and therefore smaller fees.
  const confirmedUTXOs = utxos
    ? (utxos as any[])
        .filter((utxo: any) => utxo.status.confirmed)
        .sort((a: any, b: any) => b.value - a.value)
    : [];

  // If amount is provided, reduce the list of UTXOs into a list that
  // contains just enough UTXOs to satisfy the `amount` requirement.
  let sliced = confirmedUTXOs;
  if (amount) {
    let sum = 0;
    let i = 0;
    for (i = 0; i < confirmedUTXOs.length; ++i) {
      sum += confirmedUTXOs[i].value;
      if (sum > amount) {
        break;
      }
    }
    if (sum < amount) {
      return [];
    }
    sliced = confirmedUTXOs.slice(0, i + 1);
  }

  const response = await fetch(
    validateAddressUrl(address, isTestnetMode || false)
  );
  const addressInfo = await response.json();
  const { isvalid, scriptPubKey } = addressInfo;
  if (!isvalid) {
    throw new Error("Invalid address");
  }

  // Iterate through the final list of UTXOs to construct the result list.
  // The result contains some extra information,
  return sliced.map((s: any) => {
    return {
      txid: s.txid,
      vout: s.vout,
      value: s.value,
      scriptPubKey: scriptPubKey,
    };
  });
};
